import { create } from 'zustand';
import { User } from './auth';
import { FormStoryPart1, FormStoryPart2 } from '../pages/teacher/stories/StoryModal';
import { FormConceptPart1, FormConceptPart2 } from '../pages/teacher/concepts/ConceptModal';

interface UseAuthState {
  loading: boolean; // Is the user still loading
  signedIn: boolean; // Is the user signed In
  user: User | null; // Contains the current user
  setLoading: (loading: boolean) => void;
  setSignedIn: (signedIn: boolean) => void;
  setUser: (user: User | null) => void;
}

// Contains all important information about the user
export const useAuth = create<UseAuthState>((set) => ({
  loading: true,
  signedIn: false,
  user: null,
  setLoading: (loading) => set({ loading }),
  setSignedIn: (signedIn) => set({ signedIn }),
  setUser: (user) => set({ user }),
}));

interface StoryFormStoreState {
  id: number | null;
  part1: FormStoryPart1 | null;
  part2: FormStoryPart2 | null;
  updateId: (newId: number | null) => void;
  updatePart1: (newForm: FormStoryPart1 | null) => void;
  updatePart2: (newForm: FormStoryPart2 | null) => void;
  resetStore: () => void;
}

const storyInitialState: Partial<StoryFormStoreState> = {
  id: null,
  part1: null,
  part2: null,
};

interface ConceptFormStoreState {
  id: number | null;
  part1: FormConceptPart1 | null;
  part2: FormConceptPart2 | null;
  updateId: (newId: number | null) => void;
  updatePart1: (newForm: FormConceptPart1 | null) => void;
  updatePart2: (newForm: FormConceptPart2 | null) => void;
  resetStore: () => void;
}

const conceptInitialState: Partial<ConceptFormStoreState> = {
  id: null,
  part1: null,
  part2: null,
};

// Contains the state and content of each part of a Story modal
export const useStoryFormStore = create<StoryFormStoreState>((set) => ({
  ...storyInitialState,
  id: null,
  part1: null,
  part2: null,
  updateId: (newId) => set({ id: newId }),
  updatePart1: (newForm) => set({ part1: newForm }),
  updatePart2: (newForm) => set({ part2: newForm }),
  resetStore: () => set(storyInitialState),
}));

// Contains the state and content of each part of a Concept modal
export const useConceptFormStore = create<ConceptFormStoreState>((set) => ({
  ...conceptInitialState,
  id: null,
  part1: null,
  part2: null,
  updateId: (newId) => set({ id: newId }),
  updatePart1: (newForm) => set({ part1: newForm }),
  updatePart2: (newForm) => set({ part2: newForm }),
  resetStore: () => set(conceptInitialState),
}));
