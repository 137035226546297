import { useTranslation } from 'react-i18next';
import { fetchAPI, UpdatedResponse } from '../../../utils/httpRequests';
import { useQuery } from 'react-query';
import { Quest, QuestStep, Story } from './Quests';
import { useParams } from 'react-router-dom';
import Loading from '../../../components/Loading';
import RenderUnsafeHTML from '../../../components/RenderUnsafeHTML';
import { ImagePinContainer } from 'react-image-pin';
import defaultMap from './default-map.png';
import {
  ImagePin,
  ImagePinContainerRef,
  NewPinEvent,
} from 'react-image-pin/dist/components/ImagePinContainer';
import React, { useRef, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import QuestStepModal from './modals/QuestStepModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlag, faLocationDot, faXmark } from '@fortawesome/free-solid-svg-icons';
import { IconDefinition } from '@fortawesome/free-brands-svg-icons';

export const startEndPins = [
  {
    positionX: 2,
    positionY: 4,
    stepOrder: -1,
    id: 'start',
    draggable: false,
  },
  {
    positionX: 95,
    positionY: 90,
    stepOrder: 99999999999999,
    id: 'end',
    draggable: false,
  },
];

export const getPinIcon = (id: string, defaultIcon: IconDefinition = faXmark) => {
  switch (id) {
    case 'start':
      return faLocationDot;
    case 'end':
      return faFlag;
    default:
      return defaultIcon;
  }
};

export const getPinBackgroundColor = (id: string, defaultColor: string = 'bg-danger') => {
  switch (id) {
    case 'start':
      return 'bg-warning';
    case 'end':
      return 'bg-info';
    default:
      return defaultColor;
  }
};

const QuestPage = () => {
  const { t } = useTranslation('global');
  const { questId } = useParams<{ questId: string }>(); // Id of the quest
  const ref = useRef<ImagePinContainerRef>(null); // Reference to the selected pin
  const [createModalOpen, setCreateModalOpen] = useState(false); // Controls the state of the modal that creates Quests
  const [editQuestStep, setEditQuestStep] = useState<QuestStep | null>(null); // Contains the QuestStep that will be edited
  const [questStepPositionX, setQuestStepPositionX] = useState<number | null>(null); // Contains the X position of the selected pin
  const [questStepPositionY, setQuestStepPositionY] = useState<number | null>(null); // Contains the Y position of the selected pin
  const [relatedStory, setRelatedStory] = useState<Story | null>(null); // Contains the related story of the current QuestStep

  // Resetting values and closing the modal
  const closeModal = async (): Promise<void> => {
    setCreateModalOpen(false);
    setEditQuestStep(null);
    setQuestStepPositionX(null);
    setQuestStepPositionY(null);
    await refetch();
    ref.current?.rerender();
  };

  // Get the current quest we are editing
  const fetchQuest = async (): Promise<Quest | null> => {
    const quest = await fetchAPI<Quest>(`/quests/${questId}`);
    if (quest.isSuccess) {
      return quest.data;
    } else {
      return null;
    }
  };

  // When clicking an existing pin, get its info to open the quest step modal properly
  const existingPinClick = (pin: ImagePin): void => {
    const qs = quest?.questSteps.find((s) => String(s.id) === pin.id);
    if (!qs || !quest) return;

    setQuestStepPositionX(pin.positionX);
    setQuestStepPositionY(pin.positionY);
    setEditQuestStep(qs);
    setRelatedStory(
      quest.masterStory.stories.at(quest.questSteps.findIndex((s) => s.id === qs.id)) || null,
    );
    setCreateModalOpen(true);
  };

  // Save the position of the click and open the modal to create a quest step
  const handleNewPin = (newPinEvent: NewPinEvent): void => {
    if (!quest) return;
    setQuestStepPositionX(newPinEvent.positionX);
    setQuestStepPositionY(newPinEvent.positionY);
    setRelatedStory(quest.masterStory.stories.at(quest.questSteps.length) || null);
    setCreateModalOpen(true);
  };

  // When dragging a pin, update its coordinates, then refetch, and rerender when it is let go
  const handleDraggedPin = async (pin: ImagePin): Promise<void> => {
    const questStep = quest?.questSteps.find((qs) => String(qs.id) === pin.id);
    if (!questStep) {
      return;
    }

    await fetchAPI<UpdatedResponse>(`/quests/steps/${questStep.id}`, {
      method: 'PUT',
      body: {
        ...questStep,
        positionX: pin.positionX,
        positionY: pin.positionY,
        conceptId: questStep.concept.id,
      },
    });

    if (questStep) {
      questStep.positionX = pin.positionX;
      questStep.positionY = pin.positionY;
    }

    await refetch();
    await ref.current?.rerender();
  };

  // Contains the quest and its state
  const { data: quest, isLoading, refetch } = useQuery(`quest-${questId}`, fetchQuest);

  return isLoading ? (
    <Loading />
  ) : (
    <>
      <div className='mx-3 mx-lg-5 my-2'>
        {!quest ? (
          <div>Quest not found...</div>
        ) : (
          <div>
            <h1>
              {t('quest')} - {quest.name}
            </h1>
            <div>
              <RenderUnsafeHTML className='fs-5' html={quest.introduction} />
            </div>
            <div>
              <ImagePinContainer
                ref={ref}
                image={defaultMap}
                pins={[...startEndPins, ...quest.questSteps]
                  .sort((a, b) => a.stepOrder - b.stepOrder)
                  .map((s) => {
                    return {
                      positionX: s.positionX,
                      positionY: s.positionY,
                      id: String(s.id),
                      draggable: s.id !== 'start' && s.id !== 'end',
                    };
                  })}
                customPinComponent={(pin) => (
                  <button
                    className={`rounded-circle shadow-sm ${getPinBackgroundColor(pin.id)}`}
                    style={{ width: '3em', height: '3em' }}
                  >
                    <FontAwesomeIcon icon={getPinIcon(pin.id)} size='2x' className='text-white' />
                  </button>
                )}
                arrow={{ color: '#000' }}
                onNewPin={handleNewPin}
                onExistingPin={existingPinClick}
                onDraggedPin={handleDraggedPin}
                draggable={true}
              />
            </div>
          </div>
        )}
      </div>
      {!!questStepPositionX && !!questStepPositionY && (
        <QuestStepModal
          isOpen={createModalOpen}
          onClose={closeModal}
          questId={Number(questId)}
          relatedStory={relatedStory}
          editQuestStep={editQuestStep}
          positionX={questStepPositionX}
          positionY={questStepPositionY}
        />
      )}
    </>
  );
};

export default QuestPage;
