import FancyTextArea from '../../../../components/FancyTextArea';
import { useStoryFormStore } from '../../../../utils/stores';
import { useForm } from 'react-hook-form';
import { FormStory, FormStoryPart2 } from '../StoryModal';
import { CreatedResponse, fetchAPI, UpdatedResponse } from '../../../../utils/httpRequests';
import { useEffect } from 'react';
import { useWizard } from 'react-use-wizard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

interface StoryWizardStep2Props {
  closeModal: () => void; // Closing function
  masterStoryId: number; // Parent MasterStory ID
}

const StoryWizardStep2 = ({ closeModal, masterStoryId }: StoryWizardStep2Props) => {
  const { t } = useTranslation('global');

  // Used to obtain values of Step 1 and 2, and save values for Step 2
  const { id, part1, part2, updatePart2, updateId, resetStore } = useStoryFormStore();

  const { previousStep } = useWizard(); // Contains Step 1 of the modal

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
    watch,
    getValues,
  } = useForm<FormStoryPart2>({
    defaultValues: {
      hasDifferentiation: true,
    },
  });

  useEffect(() => {
    // Setting the values in the form to what was saved
    reset({
      hasDifferentiation: part2?.hasDifferentiation ?? true,
      differentiationSetting: part2?.differentiationSetting,
    });
  }, [part2, reset]);

  // Close the modal and reset the store
  const onClose = () => {
    resetStore();
    closeModal();
  };

  const differentiation = watch('hasDifferentiation');

  // Merge the 2 steps when the form is submitted
  const handleFormMerge = async (data: FormStoryPart2): Promise<void> => {
    if (!!part1 && !!data) {
      // Update if the story exists, otherwise create
      if (id) {
        await updateStory({
          ...part1,
          ...data,
          masterStoryId: masterStoryId,
        });
      } else {
        await createStory({
          ...part1,
          ...data,
          masterStoryId: masterStoryId,
        });
      }
    }
  };

  const createStory = async (data: FormStory) => {
    // Set default values if no differentiation
    const body = data.hasDifferentiation
      ? data
      : {
          hasDifferentiation: false,
          title: data.title,
          setting: data.setting,
          epilogue: data.epilogue,
          masterStoryId: masterStoryId,
        };

    const response = await fetchAPI<CreatedResponse>('/stories', {
      method: 'POST',
      body,
    });

    if (response.isSuccess && response.data.created) {
      updateId(response.data.created);
      onClose();
    }
  };

  const updateStory = async (data: FormStory) => {
    // Set default values if no differentiation
    const body = data.hasDifferentiation
      ? data
      : {
          hasDifferentiation: false,
          title: data.title,
          setting: data.setting,
          epilogue: data.epilogue,
          masterStoryId: masterStoryId,
        };

    const response = await fetchAPI<UpdatedResponse>(`/stories/${id}`, {
      method: 'PUT',
      body,
    });

    if (response.isSuccess && response.data.updated) {
      onClose();
    }
  };

  // Save Step 2 and go back to Step 1
  const handlePreviousStep = (): void => {
    updatePart2(getValues());
    previousStep();
  };

  return (
    <form onSubmit={handleSubmit(handleFormMerge)}>
      <div className='mb-3'>
        <div className='form-check form-switch'>
          <input
            className='form-check-input'
            type='checkbox'
            role='switch'
            id='storyHasDifferentiation'
            {...register('hasDifferentiation')}
          />
          <label className='form-check-label' htmlFor='storyHasDifferentiation'>
            {t('activateDifferentiation')}
          </label>
        </div>
      </div>
      {differentiation && (
        <>
          <div className='mb-3'>
            <label htmlFor='storyDifferentiationSetting' className='form-label'>
              {t('differentiationSetting')}
            </label>
            <FancyTextArea<FormStoryPart2>
              id='storyDifferentiationSetting'
              defaultValue={''}
              name='differentiationSetting'
              required={true}
              control={control}
              className={`${errors.differentiationSetting ? 'is-invalid' : ''}`}
            />
            {errors.differentiationSetting && (
              <span className='invalid-feedback'>{t('differentiationSetting.required')}</span>
            )}
          </div>
        </>
      )}
      <div className='d-flex gap-2 mb-3'>
        <button type='button' className='btn btn-secondary' onClick={handlePreviousStep}>
          <FontAwesomeIcon icon={faArrowLeft} className='me-1' /> {t('previousStep')}
        </button>
        <button type='submit' className='btn btn-primary'>
          {id ? t('story.edit') : t('story.create')}
        </button>
      </div>
    </form>
  );
};

export default StoryWizardStep2;
