import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPencil,
  faPlus,
  faTrash,
  faChevronRight,
  faChevronDown,
} from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { fetchAPI } from '../../../utils/httpRequests';
import { useState } from 'react';
import Loading from '../../../components/Loading';
import StoryModal from './StoryModal';
import MasterStoryModal from './MasterStoryModal';
import { MasterStory, Story } from '../quests/Quests';

const StoriesPage = () => {
  const { t } = useTranslation('global');
  const [createModalOpen, setCreateModalOpen] = useState(false); // Controls the state of the modal that creates Stories
  const [createMasterModalOpen, setCreateMasterModalOpen] = useState(false); // Controls the state of the modal that creates MasterStories
  const [editStory, setEditStory] = useState<Story | null>(null); // Contains the Story that will be edited
  const [editMasterStory, setEditMasterStory] = useState<MasterStory | null>(null); // Contains the MasterStory that will be edited
  const [parentMasterStoryId, setParentMasterStoryId] = useState<number>(0); // Contains the parent MasterStory of the Story selected story
  const [collapseStates, setCollapseStates] = useState<{ [key: number]: boolean }>({}); // Contains the collapse state of each MasterStory

  // Get all MasterStories, including their child Stories
  const fetchMasterStories = async (): Promise<MasterStory[] | null> => {
    const masterStories = await fetchAPI<MasterStory[]>(`/master-stories`);
    if (masterStories.isSuccess) {
      return masterStories.data;
    } else {
      return null;
    }
  };

  // Create a Story, by passing the parent MasterStory as a parameter
  const createStory = (parentMasterStory: MasterStory) => () => {
    setParentMasterStoryId(parentMasterStory.id);
    setCreateModalOpen(true);

    // Make sure a MasterStory is always opened after creating a story
    setCollapseStates((prevState) => ({
      ...prevState,
      [parentMasterStory.id]: true,
    }));
  };

  const updateMasterStory = (masterStory: MasterStory) => () => {
    setEditMasterStory(masterStory);
    setCreateMasterModalOpen(true);
  };

  const updateStory = (story: Story, parentMasterStory: MasterStory) => () => {
    setParentMasterStoryId(parentMasterStory.id);
    setEditStory(story);
    setCreateModalOpen(true);
  };

  // Close any opened modal
  const closeModal = async (): Promise<void> => {
    await refetch();
    setEditMasterStory(null);
    setEditStory(null);
    setCreateModalOpen(false);
    setCreateMasterModalOpen(false);
  };

  const deleteMasterStory = (masterStory: MasterStory) => async () => {
    const response = await fetchAPI(`/master-stories/${masterStory.id}`, {
      method: 'DELETE',
    });

    if (response.isSuccess) {
      await refetch();
    }
  };

  const deleteStory = (story: Story) => async () => {
    const response = await fetchAPI(`/stories/${story.id}`, {
      method: 'DELETE',
    });

    if (response.isSuccess) {
      await refetch();
    }
  };

  // MasterStories and their state
  const { data: masterStories, isLoading, refetch } = useQuery('masterStories', fetchMasterStories);

  // Toggling collapse state of a MasterStory
  const toggleCollapse = (masterStoryId: number) => {
    setCollapseStates((prevState) => ({
      ...prevState,
      [masterStoryId]: !prevState[masterStoryId],
    }));
  };

  return (
    <>
      <div className='mx-md-5 my-3'>
        <div className='d-flex align-items-center justify-content-between'>
          <div>
            <h1>{t('master_stories.page')}</h1>
          </div>
          <div>
            <button
              onClick={() => {
                setCreateMasterModalOpen(true);
              }}
              className='btn btn-primary'
            >
              <FontAwesomeIcon icon={faPlus} className='me-1' /> {t('master_story.create')}
            </button>
          </div>
        </div>
        <div>
          {isLoading ? (
            <Loading />
          ) : (
            masterStories
              ?.sort((a, b) => a.id - b.id)
              .map((masterStory) => (
                <div className='card my-3' key={masterStory.id}>
                  <div className='card-body'>
                    <div className='d-flex align-items-center justify-content-between'>
                      <button
                        onClick={() => toggleCollapse(masterStory.id)}
                        className='d-flex gap-2'
                      >
                        <FontAwesomeIcon
                          className='pt-1'
                          icon={collapseStates[masterStory.id] ? faChevronDown : faChevronRight}
                        />
                        <h5 className='card-title'>{masterStory.name}</h5>
                      </button>
                      <div className='d-flex gap-2'>
                        <button
                          className='btn btn-primary'
                          onClick={updateMasterStory(masterStory)}
                        >
                          <FontAwesomeIcon icon={faPencil} className='me-2' /> {t('edit')}
                        </button>
                        <button className='btn btn-danger' onClick={deleteMasterStory(masterStory)}>
                          <FontAwesomeIcon icon={faTrash} className='me-2' /> {t('delete')}
                        </button>
                      </div>
                    </div>
                    <div
                      className={`collapse ${collapseStates[masterStory.id] ? 'show' : ''}`}
                      id={`collapseStories${masterStory.id}`}
                    >
                      {masterStory.stories?.map((story) => (
                        <div className='card my-2' key={story.id}>
                          <div className='card-body d-flex align-items-center justify-content-between'>
                            <h5 className='card-title'>{story.title}</h5>
                            <div className='d-flex gap-2'>
                              <button
                                className='btn btn-primary'
                                onClick={updateStory(story, masterStory)}
                              >
                                <FontAwesomeIcon icon={faPencil} />
                              </button>
                              <button className='btn btn-danger' onClick={deleteStory(story)}>
                                <FontAwesomeIcon icon={faTrash} />
                              </button>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className='d-flex gap-2 mt-3'>
                      <button className='btn btn-success' onClick={createStory(masterStory)}>
                        <FontAwesomeIcon icon={faPlus} className='me-2' /> {t('story.create')}
                      </button>
                    </div>
                  </div>
                </div>
              ))
          )}
        </div>
      </div>
      <MasterStoryModal
        isOpen={createMasterModalOpen}
        onClose={closeModal}
        editMasterStory={editMasterStory}
      />
      <StoryModal
        isOpen={createModalOpen}
        onClose={closeModal}
        editStory={editStory}
        masterStoryId={parentMasterStoryId}
      />
    </>
  );
};

export default StoriesPage;
