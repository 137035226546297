import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Control, Controller, FieldValues, Path, PathValue } from 'react-hook-form';

// Allow the user to stylize their text in forms
const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
    ['link', 'image', 'video'],
    ['clean'],
  ],
};

const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'video',
];

export interface FancyTextAreaProps<T extends FieldValues> {
  id: string;
  name: Path<T>;
  control: Control<T>;
  defaultValue: PathValue<T, Path<T>>;
  required: boolean;
  className?: string;
}

const FancyTextArea = <T extends FieldValues>({
  id,
  name,
  control,
  defaultValue,
  required,
  className = '',
}: FancyTextAreaProps<T>) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={{ required }}
      render={({ field: { value, onChange } }) => (
        <ReactQuill
          className={className}
          id={id}
          value={value}
          modules={modules}
          formats={formats}
          onChange={(v) => onChange(v === '<p><br></p>' ? '' : v)}
        />
      )}
    />
  );
};

export default FancyTextArea;
