import { useEffect, useState } from 'react'; // Import useState
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { fetchCourse, updateCourse } from '../../../utils/httpRequests';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { Tooltip } from 'react-tooltip';
import Loading from '../../../components/Loading';

const Settings = () => {
  const { t } = useTranslation('global');
  const { courseId } = useParams<{ courseId: string }>();
  const [accessCodeCopied, setAccessCodeCopied] = useState(t('copyAccessCode'));
  const [isChecked, setIsChecked] = useState(true);

  // Handle toggle change
  const handleToggle = async () => {
    if (course?.accessCodeBlocked === !isChecked) {
      course!.accessCodeBlocked = !course?.accessCodeBlocked;
    }
    setIsChecked(!isChecked);

    // Update course
    await updateCourse(courseId, {
      name: course!.name,
      accessCode: course!.accessCode,
      accessCodeBlocked: course!.accessCodeBlocked, // New Value
    });
  };

  const { data: course, isLoading } = useQuery(['course', courseId], () => fetchCourse(courseId));

  //   Copies generated password to clipboard
  const copyToClipboard = () => {
    navigator.clipboard.writeText(course!.accessCode);
    navigator.clipboard.readText();
    setAccessCodeCopied(t('accessCodeCopied'));
  };

  useEffect(() => {
    if (course?.accessCodeBlocked !== undefined) {
      setIsChecked(!course.accessCodeBlocked); // Set to false if blocked, true if not blocked
    }
  }, [course]);

  return (
    <div className='m-3'>
      <h1>{t('settings')}</h1>

      {/* Check if the course is still loading */}
      {isLoading ? (
        <Loading insideModal={'default'} />
      ) : (
        <div className='shadow p-3 mb-5 bg-white rounded'>
          <div className='d-flex justify-content-between align-items-center'>
            <h2>{t('accessCode') + ' ' + course?.accessCode}</h2>
            <div>
              <a data-tooltip-id='my-tooltip' data-tooltip-content={accessCodeCopied}>
                <FontAwesomeIcon
                  icon={faCopy}
                  size='2x'
                  style={{ cursor: 'pointer' }}
                  onClick={copyToClipboard}
                />
              </a>
              <Tooltip id='my-tooltip' />
            </div>
          </div>
          <div className='form-check form-switch'>
            <label className='form-check-label' htmlFor='flexSwitchCheckChecked'>
              {isChecked ? t('accessCodeActivated') : t('accessCodeBlocked')}
            </label>
            <input
              className='form-check-input'
              type='checkbox'
              role='switch'
              id='flexSwitchCheckChecked'
              checked={isChecked}
              onChange={handleToggle}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Settings;
