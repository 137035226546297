import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import FancyTextArea from '../../../components/FancyTextArea';
import { useForm } from 'react-hook-form';
import { CreatedResponse, fetchAPI, UpdatedResponse } from '../../../utils/httpRequests';
import { useEffect } from 'react';
import { MasterStory } from '../quests/Quests';

interface MasterStoryModalProps {
  isOpen: boolean; // State of the modal
  onClose: () => void; // Closing function
  editMasterStory?: MasterStory | null; // Defines if we edit or create a MasterStory
}

interface FormMasterStory {
  name: string;
  introduction: string;
  conclusion: string;
}

const MasterStoryModal = ({ isOpen, onClose, editMasterStory }: MasterStoryModalProps) => {
  const { t } = useTranslation('global');

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<FormMasterStory>();

  useEffect(() => {
    // Reset the form values after it's closed
    reset({
      name: editMasterStory?.name,
      introduction: editMasterStory?.introduction,
      conclusion: editMasterStory?.conclusion,
    });
  }, [editMasterStory, reset, onClose]);

  const closeModal = (): void => {
    onClose();
  };

  const handleFormSubmit = async (data: FormMasterStory): Promise<void> => {
    if (editMasterStory) {
      await updateMasterStory(data);
    } else {
      await createMasterStory(data);
    }
  };

  const createMasterStory = async (data: FormMasterStory): Promise<void> => {
    const body = data;

    const response = await fetchAPI<CreatedResponse>('/master-stories', {
      method: 'POST',
      body,
    });

    if (response.isSuccess && response.data.created) {
      onClose();
    }
  };

  const updateMasterStory = async (data: FormMasterStory): Promise<void> => {
    const body = data;

    const response = await fetchAPI<UpdatedResponse>(`/master-stories/${editMasterStory?.id}`, {
      method: 'PUT',
      body,
    });

    if (response.isSuccess && response.data.updated) {
      onClose();
    }
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal} contentLabel='Master Story Modal'>
      <div className='w-100 h-100'>
        <div className='d-flex w-100 justify-content-between'>
          <div>
            <h2>{editMasterStory ? t('master_story.edit') : t('master_story.create')}</h2>
          </div>
          <button onClick={closeModal} className='btn btn-primary'>
            <FontAwesomeIcon icon={faClose} />
          </button>
        </div>
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <div className='mb-3'>
            <label htmlFor='masterStoryName' className='form-label'>
              {t('name')}
            </label>
            <input
              type='text'
              id='masterStoryName'
              className={`form-control ${errors.name ? 'is-invalid' : ''}`}
              {...register('name', { required: true })}
            />
            {errors.name && <span className='invalid-feedback'>{t('title.required')}</span>}
          </div>

          <div className='mb-3'>
            <label htmlFor='masterStoryIntro' className='form-label'>
              {t('introduction')}
            </label>
            <FancyTextArea<FormMasterStory>
              id='masterStoryIntro'
              defaultValue={''}
              name='introduction'
              required={true}
              control={control}
              className={`${errors.introduction ? 'is-invalid' : ''}`}
            />
            {errors.introduction && (
              <span className='invalid-feedback'>{t('setting.required')}</span>
            )}
          </div>
          <div className='mb-3'>
            <label htmlFor='storyEpilogue' className='form-label'>
              {t('conclusion')}
            </label>
            <FancyTextArea<FormMasterStory>
              id='storyEpilogue'
              defaultValue={''}
              name='conclusion'
              required={true}
              control={control}
              className={`${errors.conclusion ? 'is-invalid' : ''}`}
            />
            {errors.conclusion && (
              <span className='invalid-feedback'>{t('epilogue.required')}</span>
            )}
          </div>

          <button type='submit' className='btn btn-primary mb-3'>
            {editMasterStory ? t('master_story.edit') : t('master_story.create')}
          </button>
        </form>
      </div>
    </Modal>
  );
};

Modal.setAppElement('#root');

export default MasterStoryModal;
