import { useState } from 'react';
import CreateQuestModal from './modals/CreateQuestModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faPencil, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { fetchAPI } from '../../../utils/httpRequests';
import { useQuery } from 'react-query';
import RenderUnsafeHTML from '../../../components/RenderUnsafeHTML';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { QuizQuestionForm } from '../../../components/quiz/QuizQuestion';
import { User } from '../../../utils/auth';
import { ConceptFileSubmissionTypes } from '../concepts/ConceptModal';

export interface Course {
  id: number;
  name: string;
  accessCode: string;
  accessCodeBlocked: boolean;
}

interface BaseStory {
  id: number;
  title: string;
  setting: string;
  epilogue: string;
  order: number;
  storyOrder: number;
}

interface StoryWithDifferentiation extends BaseStory {
  hasDifferentiation: true;
  differentiationSetting: string;
}

interface StoryWithoutDifferentiation extends BaseStory {
  hasDifferentiation: false;
}

export type Story = StoryWithDifferentiation | StoryWithoutDifferentiation;

export interface MasterStory {
  id: number;
  user: User;
  introduction: string;
  conclusion: string;
  name: string;
  stories: Story[];
}

interface BaseConcept {
  id: number;
  title: string;
  learning: string;
  practice: string;
  conceptQuestions: QuizQuestionForm[];
  fileSubmissionType: ConceptFileSubmissionTypes;
}

interface ConceptWithDifferentiation extends BaseConcept {
  hasDifferentiation: true;
  differentiationLearning: string;
  differentiationPractice: string;
}

interface ConceptWithoutDifferentiation extends BaseConcept {
  hasDifferentiation: false;
}

export interface MasterStory {
  id: number;
  name: string;
  introduction: string;
  conclusion: string;
  stories: Story[];
}

export type Concept = ConceptWithDifferentiation | ConceptWithoutDifferentiation;
export interface QuestStep {
  id: number;
  positionX: number;
  positionY: number;
  dueDate: string;
  rewardXp: number;
  rewardGold: number;
  stepOrder: number;
  concept: Concept;
}

interface QuestCourse {
  id: number;
  name: string;
}

export interface Quest {
  id: number;
  name: string;
  introduction: string;
  questSteps: QuestStep[];
  masterStory: MasterStory;
  courses: QuestCourse[];
}

export type QuestLogStatus =
  | 'NOT_STARTED'
  | 'IN_PROGRESS'
  | 'COMPLETED'
  | 'COMPLETED_WITH_DIFFERENTIATION'
  | 'FAILED'
  | 'START'
  | 'END';

export interface QuestStepLog {
  questStepId: number;
  status: QuestLogStatus;
}

export interface QuestLog {
  questId: number;
  status: QuestLogStatus;
  questStepLogResponses: QuestStepLog[];
}

const Quests = () => {
  const { t } = useTranslation('global');
  const [createModalOpen, setCreateModalOpen] = useState(false); // Controls the state of the modal that creates quests
  const [editQuest, setEditQuest] = useState<Quest | null>(null); // Contains the quest that will be edited

  // Get all quests
  const fetchQuests = async (): Promise<Quest[] | null> => {
    const quests = await fetchAPI<Quest[]>(`/quests`);
    if (quests.isSuccess) {
      return quests.data;
    } else {
      return null;
    }
  };

  const deleteQuest = (quest: Quest) => async () => {
    const response = await fetchAPI(`/quests/${quest.id}`, {
      method: 'DELETE',
    });

    if (response.isSuccess) {
      await refetch();
    }
  };

  const closeModal = async (): Promise<void> => {
    await refetch();
    setCreateModalOpen(false);
    setEditQuest(null);
  };

  const updateQuest = (quest: Quest) => () => {
    setEditQuest(quest);
    setCreateModalOpen(true);
  };

  // Contains the quests and their state
  const { data: quests, isLoading, refetch } = useQuery('quests', fetchQuests);

  return (
    <>
      <div className='px-md-5 py-3'>
        <h2>{t('quests.title')}</h2>
        <button className='btn btn-primary mb-2' onClick={() => setCreateModalOpen(true)}>
          <FontAwesomeIcon icon={faPlus} className='me-1' /> {t('quests.create_button')}
        </button>
        <div>
          {isLoading ? (
            <div className='flex align-items-center'>
              Loading...
              <div className='me-2 spinner-border' role='status'>
                <span className='visually-hidden'>{t('loading')}</span>
              </div>
            </div>
          ) : (
            <div>
              {quests?.map((quest) => (
                <div className='card my-2' key={quest.id}>
                  <div className='card-body'>
                    <h5 className='card-title'>{quest.name}</h5>
                    <p className='card-text'>
                      <RenderUnsafeHTML html={quest.introduction} />
                    </p>
                    <div className='d-flex gap-2'>
                      <Link to={`/quests/${quest.id}`} className='btn btn-outline-primary'>
                        <FontAwesomeIcon icon={faEye} className='me-2' /> {t('view')}
                      </Link>
                      <button className='btn btn-primary' onClick={updateQuest(quest)}>
                        <FontAwesomeIcon icon={faPencil} className='me-2' /> {t('edit')}
                      </button>
                      <button className='btn btn-danger' onClick={deleteQuest(quest)}>
                        <FontAwesomeIcon icon={faTrash} className='me-2' /> {t('delete')}
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <CreateQuestModal isOpen={createModalOpen} onClose={closeModal} editQuest={editQuest} />
    </>
  );
};

export default Quests;
