import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

interface JoinClassModalProps {
  isOpen: boolean;
  statusCode: number;
  onClose: () => void;
}
const JoinClassModal = ({ isOpen, statusCode, onClose }: JoinClassModalProps) => {
  const { t } = useTranslation('global');
  const queryClient = useQueryClient();
  const closeModal = async (): Promise<void> => {
    await queryClient.invalidateQueries('participants');
    onClose();
  };

  return (
    <>
      {isOpen && (
        <div className='modal-backdrop show' style={{ backgroundColor: 'rgba(0, 0, 0, 1)' }}></div>
      )}
      <div
        className={`modal ${isOpen ? 'show' : ''}`}
        tabIndex={-1}
        role='dialog'
        style={{ display: isOpen ? 'block' : 'none' }}
      >
        <div className='modal-dialog' role='document'>
          <div className='modal-content'>
            <div className='modal-header'>
              {statusCode == 200 ? (
                <h5 className='modal-title'>{t('joinedClass')}</h5>
              ) : (
                <h5 className='modal-title'>{t('notJoinedClass')}</h5>
              )}
              <button
                type='button'
                className='btn btn-close'
                onClick={onClose}
                aria-label='Close'
              ></button>
            </div>
            <div className='modal-body'>
              {/* Will show message based of request results */}
              {statusCode === 200 ? (
                <p>{t('joinedClassMessage')}</p>
              ) : statusCode === 404 ? (
                <p>{t('notJoinedClassError404')}</p>
              ) : statusCode === 422 ? (
                <p>{t('notJoinedClassError422')}</p>
              ) : (
                <p>{t('unexpectedError')}</p>
              )}
            </div>
            <div className='modal-footer'>
              {/* Button to allow student to configure avatar */}
              {statusCode === 200 && (
                <button type='button' className='btn btn-primary'>
                  {t('createAvatar')}
                </button>
              )}

              <button type='button' className='btn btn-secondary' onClick={closeModal}>
                {t('close')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default JoinClassModal;
