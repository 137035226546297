import { useQuery } from 'react-query';
import { fetchAPI } from '../../utils/httpRequests';
import './Welcome.css';
import { Course } from '../teacher/quests/Quests';
import { useAuth } from '../../utils/stores';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

interface ParticipantCourse {
  id: number;
  name: string;
}

interface Avatar {
  avatar: string;
  code: string;
  id: number;
  role: string;
  course: ParticipantCourse;
  xp: number;
  gold: number;
  life: number;
}

const Welcome = () => {
  const { t } = useTranslation('global');
  const { user, signedIn } = useAuth();

  const fetchAvatars = async (): Promise<Avatar[] | null> => {
    const avatars = await fetchAPI<Avatar[]>(`/participants`);
    if (avatars.isSuccess) {
      return avatars.data;
    } else {
      return null;
    }
  };

  // Function to get all Classes of a
  const fetchClasses = async (): Promise<Course[] | null> => {
    const classes = await fetchAPI<Course[]>('/courses');
    if (classes.isSuccess) {
      return classes.data;
    } else {
      return null;
    }
  };

  const { data: classes, isLoading: classesLoading } = useQuery('courses', fetchClasses);
  const { data: avatars, isLoading: avatarsLoading } = useQuery('participants', fetchAvatars);
  return (
    <>
      <div>
        {classesLoading || avatarsLoading ? (
          <div className='px-md-5'>
            <div className='flex align-items-center'>
              {t('loading')}
              <div className='me-2 spinner-border' role='status'>
                <span className='visually-hidden'>{t('loading')}</span>
              </div>
            </div>
          </div>
        ) : (
          <div>
            {/* For logged in teacher, will show card for each class */}
            {signedIn && user?.teacher ? (
              <div className='container'>
                {classes && classes.length > 0 ? (
                  <div className='row mw-100'>
                    {classes?.map((course) => (
                      <div className='col-md-4 col-xl-3' key={course.id}>
                        <div className='card bg-c-blue order-card'>
                          <Link className='card-block link-style' to={`/courses/${course.id}`}>
                            <h6 className='m-b-20'>{course.name}</h6>
                            {course.accessCodeBlocked ? (
                              <div>{t('courseBlocked')}</div>
                            ) : (
                              <p className='m-b-0'>
                                {t('accessCode')}
                                <span>{course.accessCode}</span>
                              </p>
                            )}
                          </Link>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className='container mt-3'>
                    <h5>{t('noClassesMessage')}</h5>
                  </div>
                )}
              </div>
            ) : (
              <div>
                {avatars && avatars.length > 0 ? (
                  <div className='row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xxl-4 mw-100'>
                    {/* For logged in student, will show card for each class */}
                    {avatars?.map((avatar) => (
                      <Link to={`/courses/${avatar.course.id}`} key={avatar.id}>
                        <div className='col text-center'>
                          <div className='wrapper'>
                            <div className='clash-card wizard'>
                              <div className='clash-card__image clash-card__image--wizard'>
                                <img
                                  src='https://s3-us-west-2.amazonaws.com/s.cdpn.io/195612/wizard.png'
                                  alt='wizard'
                                />
                              </div>
                              <div className='clash-card__unit-name'>Pirate</div>
                              <div className='clash-card__unit-stats clash-card__unit-stats--wizard clearfix'>
                                {/* Avatar info */}
                                <div className='one-third'>
                                  <div className='stat'>0</div>
                                  <div className='stat-value'>{t('level')}</div>
                                </div>

                                <div className='one-third'>
                                  <div className='stat'>{avatar.xp}</div>
                                  <div className='stat-value'>{t('XP')}</div>
                                </div>

                                <div className='one-third no-border'>
                                  <div className='stat'>{avatar.gold}</div>
                                  <div className='stat-value'>
                                    <img
                                      src={`${process.env.PUBLIC_URL}/gold-coins.png`}
                                      alt='Or Icon'
                                      className='icon-image'
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    ))}
                  </div>
                ) : (
                  <div className='container mt-3'>
                    <h5>{t('noAvatarsMessage')}</h5>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default Welcome;
