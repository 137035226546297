import SideBar from '../../components/SideBar';
import { Outlet } from 'react-router-dom';

const Course = () => {
  <div className='d-flex flex-grow-1'></div>;

  return (
    <>
      <div className='d-flex flex-grow-1'>
        <SideBar />
        <div className='flex-grow-1'>
          {/* Outlet will contain content of desired the page */}
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default Course;
