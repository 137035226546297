import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import CreateClassModal from './modals/CreateClassModal';
import { useAuth } from '../utils/stores';
import { fetchAPI } from '../utils/httpRequests';
import JoinClassModal from './modals/JoinClassModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { removePreviousTokens } from '../utils/auth';
import './Navbar.css';

const Navbar = () => {
  const [createModalOpen, setCreateModalOpen] = useState(false);
  interface JoinCourse {
    accessCode: string;
  }

  const [joinModalOpen, setJoinModalOpen] = useState(false);
  const [joinedClassStatus, setJoinedClassStatus] = useState(404);
  const { t } = useTranslation('global');

  const openModal = async (): Promise<void> => {
    setCreateModalOpen(true);
  };

  const closeCreateModal = async (): Promise<void> => {
    setCreateModalOpen(false);
  };

  const { user, signedIn } = useAuth();
  const { handleSubmit, register, watch } = useForm<JoinCourse>();
  const accessCode = watch('accessCode');

  const closeJoinModal = async (): Promise<void> => {
    setJoinModalOpen(false);
  };

  const onSubmit = async (data: JoinCourse) => {
    await joinCourse(data);
  };

  // Function to send request to join a class
  const joinCourse = async (body: JoinCourse): Promise<void> => {
    const response = await fetchAPI(`/courses/join`, {
      method: 'POST',
      body,
    });
    setJoinedClassStatus(response.statusCode);
    setJoinModalOpen(true);
  };

  return (
    <>
      <nav className='navbar navbar-expand-md navbar-dark bg-dark'>
        <div className='container-fluid px-3'>
          {!signedIn && (
            <Link className='navbar-brand' to='/'>
              {t('iq')}
            </Link>
          )}
          {signedIn && (
            <Link className='navbar-brand' to='/welcome'>
              {t('iq')}
            </Link>
          )}

          <button
            className='navbar-toggler'
            type='button'
            data-bs-toggle='collapse'
            data-bs-target='#navbarCollapse'
            aria-controls='navbarCollapse'
            aria-expanded='false'
            aria-label='Toggle navigation'
          >
            <span className='navbar-toggler-icon'></span>
          </button>

          {/* Below content will collapse for a smaller screen */}
          <div className='collapse navbar-collapse' id='navbarCollapse'>
            {/* Content for signed in TEACHER */}
            {signedIn && user?.teacher && (
              <>
                <ul className='navbar-nav'>
                  <li className='nav-item'>
                    <Link className='nav-link' to='/welcome'>
                      {t('classes')}
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link className='nav-link' to='/quests'>
                      {t('quests')}
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link className='nav-link' to='/stories'>
                      {t('stories')}
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link className='nav-link' to='/concepts'>
                      {t('concepts')}
                    </Link>
                  </li>
                </ul>
                <div className='d-flex ms-auto'>
                  <button
                    className='btn btn-success my-2 my-sm-0'
                    onClick={openModal}
                    style={{ whiteSpace: 'nowrap' }}
                  >
                    {t('createCourse')}
                  </button>
                </div>
              </>
            )}
            {signedIn && user?.role == 'STUDENT' && (
              <form role='search' onSubmit={handleSubmit(onSubmit)} className='d-flex ms-auto'>
                {/* Form for student to join class */}
                <input
                  className='form-control me-2'
                  type='search'
                  placeholder={t('classCode')}
                  {...register('accessCode')}
                  aria-label='Search'
                ></input>
                <button
                  className='btn btn-success my-2 my-sm-0'
                  type='submit'
                  style={{ whiteSpace: 'nowrap' }}
                  disabled={!accessCode}
                >
                  {t('join')}
                </button>
              </form>
            )}
            {/* Content for any signed in user */}
            {signedIn && (
              <ul className='navbar-nav'>
                <li className='nav-item dropdown'>
                  <a
                    className='nav-link dropdown-toggle'
                    href='#'
                    role='button'
                    data-bs-toggle='dropdown'
                    aria-expanded='false'
                  >
                    <FontAwesomeIcon className='icon' icon={faUser} />
                  </a>
                  <ul className='dropdown-menu dropdown-menu-end'>
                    <li>
                      <Link
                        className='dropdown-item'
                        onClick={removePreviousTokens}
                        to='/auth/sign-in'
                      >
                        {t('logout')}
                      </Link>
                    </li>
                    <li>
                      <Link className='dropdown-item' to='/settings'>
                        {t('settings')}
                      </Link>
                    </li>
                  </ul>
                </li>
              </ul>
            )}
            {/* Content for signed out user */}
            {!signedIn && (
              <ul className='navbar-nav ms-auto'>
                <li className='nav-item'>
                  <Link className='nav-link' to='/auth/sign-up'>
                    {t('createAccount')}
                  </Link>
                </li>

                <li className='nav-item'>
                  <Link className='nav-link' to='/auth/sign-in'>
                    {t('login')}
                  </Link>
                </li>
              </ul>
            )}
          </div>
        </div>
      </nav>

      {/* Modal related to joinning a class */}
      <JoinClassModal
        isOpen={joinModalOpen}
        statusCode={joinedClassStatus}
        onClose={closeJoinModal}
      />

      {/* Modal to create a class */}
      <CreateClassModal isOpen={createModalOpen} onClose={closeCreateModal} />
    </>
  );
};

export default Navbar;
