import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { useWizard } from 'react-use-wizard';
import { useTranslation } from 'react-i18next';
import { QuestLogStatus, QuestStep } from '../../pages/teacher/quests/Quests';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { fetchAPI, fetchCourse, uploadFiles } from '../../utils/httpRequests';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../utils/stores';
import { QuizQuestionForm } from '../quiz/QuizQuestion';

interface StudentQuestQuizModalProps {
  questStep: QuestStep;
  status: QuestLogStatus;
  differentiation: boolean;
  updateCompletionStatus: () => void;
}

interface QuestionSubmissions {
  questions: QuestionSubmission[];
  files: File[];
  assetIds: string[];
}

interface QuestionSubmission {
  questionId: number;
  answer: string[] | string | null;
  files: File[];
  assetIds: string[];
}

interface QuestionAnswerFeedback {
  questionId: number;
  correct: boolean;
  feedback: string;
  success: boolean;
}

const StudentQuestQuizModal = ({
  questStep,
  status,
  differentiation,
  updateCompletionStatus,
}: StudentQuestQuizModalProps) => {
  const { t } = useTranslation('global');
  const completed =
    status === 'COMPLETED' ||
    status === 'COMPLETED_WITH_DIFFERENTIATION' ||
    (status === 'FAILED' && !differentiation);
  const [canGoToNextPage, setCanGoToNextPage] = useState(false); // Contains the state of the "next page" button
  const { previousStep, nextStep } = useWizard(); // Contains the adjacent steps to the quiz
  const { courseId } = useParams<{ courseId: string }>(); // Id of the course
  const { user } = useAuth(); // Current User
  const [answers, setAnswers] = useState<QuestionAnswerFeedback[] | null>(null); // Contains a list of the answers and feedback
  const filesRequired = questStep.concept.fileSubmissionType === 'FOR_EACH_QUESTION' && !completed;
  const questions = questStep.concept.conceptQuestions.filter(
    (q) => q.differentiation === differentiation,
  );

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useForm<QuestionSubmissions>({
    defaultValues: {
      questions: questions.map((q) => {
        return { questionId: q.id!, answer: null, files: [] };
      }),
    },
  });

  // Handle answers submission
  const onSubmit = async (data: QuestionSubmissions) => {
    // Get the course
    const course = await fetchCourse(courseId);
    if (!course) {
      return;
    }

    for (const q of data.questions) {
      if (q.files.length > 0) {
        const assetIds = await uploadFiles(q.files);
        if (assetIds) {
          q.assetIds = assetIds;
        }
      }
    }

    if (!!data.files && data.files.length > 0) {
      const assetIds = await uploadFiles(data.files);
      if (assetIds) {
        data.assetIds = assetIds;
      }
    }

    // Get the correct answers
    const response = await fetchAPI<QuestionAnswerFeedback[]>(
      `/quests/steps/${questStep.id}/answers`,
      {
        method: 'POST',
        body: {
          practiceMode: completed,
          participantId: course.participants.find((p) => p.user.id === user?.id)?.id,
          ...(!!data.assetIds && data.assetIds.length > 0 && { assetIds: data.assetIds }),
          answerQuestions: data.questions.map((q) => {
            return {
              questionId: q.questionId,
              answer: Array.isArray(q.answer) ? q.answer : [q.answer],
              assetIds: q.assetIds,
            };
          }),
        },
      },
    );

    // Set the answers and allow student to progress
    if (response.isSuccess && response.data) {
      setAnswers(response.data);
      setCanGoToNextPage(true);
      updateCompletionStatus();
    }
  };

  const FileUploadComponent = ({ q, index }: { q: QuizQuestionForm; index: number }) => {
    return (filesRequired || (q.requiresFile && !completed)) &&
      questStep.concept.fileSubmissionType !== 'PER_CONCEPT' ? (
      <>
        <Controller
          control={control}
          name={`questions.${index}.files`}
          rules={{ required: t('filesRequired') }}
          render={({ field: { onChange } }) => {
            return (
              <input
                type='file'
                multiple={true}
                className='form-control mt-2'
                onChange={(event) => {
                  if (event.target.files) {
                    onChange(event.target.files ?? []);
                  }
                }}
              />
            );
          }}
        />
        {errors.questions && errors.questions[index] && errors.questions[index]?.files && (
          <div className='text-danger' style={{ fontSize: '0.875rem' }}>
            {t('filesRequired')}
          </div>
        )}
      </>
    ) : (
      <></>
    );
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} className='mb-4'>
        {questions.map((q: QuizQuestionForm, index: number) => {
          return (
            <div className='card my-2' key={q.id}>
              <div className='card-body'>
                <h5 className='card-title'>{q.questionText}</h5>
                {q.questionValue.questionType === 'MULTIPLE_CHOICE' ||
                q.questionValue.questionType === 'MULTIPLE_SELECT' ? (
                  <div>
                    {q.questionValue.choices.map((c, i) => {
                      return (
                        <div className='form-check' key={i}>
                          <input
                            {...register(`questions.${index}.answer`, {
                              required: true,
                              value: c,
                            })}
                            type={
                              q.questionValue.questionType === 'MULTIPLE_CHOICE'
                                ? 'radio'
                                : 'checkbox'
                            }
                            className={`form-check-input ${errors.questions && errors.questions[index] && errors.questions[index]?.answer ? 'is-invalid' : ''}`}
                            id={`question-${q.id}-${i}`}
                            value={c}
                          />
                          <label className='form-check-label'>{c}</label>
                        </div>
                      );
                    })}
                    {errors.questions &&
                      errors.questions[index] &&
                      errors.questions[index]?.answer && (
                        <div className='text-danger mb-1' style={{ fontSize: '0.875rem' }}>
                          {t('answerRequired')}
                        </div>
                      )}
                    <FileUploadComponent q={q} index={index} />
                    {answers &&
                      (answers.find((qe) => qe.questionId === q.id)?.success ? (
                        <div className='alert alert-success mt-2'>{t('correctAnswer')}</div>
                      ) : (
                        <div className='alert alert-danger mt-2'>{t('incorrectAnswer')}</div>
                      ))}
                  </div>
                ) : (
                  <div>
                    <input
                      {...register(`questions.${index}.answer`, {
                        required: true,
                      })}
                      type='text'
                      className={`form-control ${errors.questions && errors.questions[index] && errors.questions[index]?.answer ? 'is-invalid' : ''}`}
                    />
                    <span className='invalid-feedback'>{t('answerRequired')}</span>
                    <FileUploadComponent q={q} index={index} />
                    {answers &&
                      (q.questionValue.questionType === 'SHORT_ANSWER' ? (
                        answers.find((qe) => qe.questionId === q.id)?.success ? (
                          <div className='alert alert-success mt-2'>{t('correctAnswer')}</div>
                        ) : (
                          <div className='alert alert-danger mt-2'>{t('incorrectAnswer')}</div>
                        )
                      ) : (
                        <div className='alert alert-info mt-2'>{t('longAnswerFeedback')}</div>
                      ))}
                  </div>
                )}
              </div>
            </div>
          );
        })}
        {questions.length > 0 ? (
          <>
            {completed && (
              <div className='alert alert-warning mt-3'>{t('practiceModeWarning')}</div>
            )}
            {!completed && questStep.concept.fileSubmissionType === 'PER_CONCEPT' && (
              <div className='mb-2'>
                <Controller
                  control={control}
                  name={'files'}
                  rules={{ required: t('filesRequired') }}
                  render={({ field: { onChange } }) => {
                    return (
                      <input
                        type='file'
                        multiple={true}
                        className='form-control mt-2'
                        onChange={(event) => {
                          if (event.target.files) {
                            onChange(event.target.files ?? []);
                          }
                        }}
                      />
                    );
                  }}
                />
                {errors.files && (
                  <div className='text-danger' style={{ fontSize: '0.875rem' }}>
                    {t('filesRequired')}
                  </div>
                )}
              </div>
            )}
            <button type='submit' className='btn btn-primary'>
              {t('submitQuestions')}
            </button>
          </>
        ) : (
          <div className='alert alert-warning mt-3'>{t('noQuestions')}</div>
        )}
      </form>
      <div className='d-flex gap-2 pb-4'>
        <button type='button' className='btn btn-secondary' onClick={previousStep}>
          <FontAwesomeIcon icon={faArrowLeft} className='me-1' /> {t('previousStep')}
        </button>
        <button
          type='button'
          className='btn btn-primary'
          disabled={!completed && !canGoToNextPage}
          onClick={nextStep}
        >
          {t('nextStep')} <FontAwesomeIcon icon={faArrowRight} className='ms-1' />
        </button>
      </div>
    </div>
  );
};

export default StudentQuestQuizModal;
