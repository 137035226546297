import {
  Control,
  FieldErrors,
  useFieldArray,
  UseFormRegister,
  UseFormWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { QuizQuestionFormChoice } from './QuizQuestion';

export interface MultipleChoiceQuestion {
  questionType: 'MULTIPLE_CHOICE';
  choices: string[];
  correctAnswer: string;
}

export interface MultipleSelectQuestion {
  questionType: 'MULTIPLE_SELECT';
  choices: string[];
  correctAnswers: string[];
}

interface MultipleChoiceQuestionProps {
  select: boolean;
  register: UseFormRegister<QuizQuestionFormChoice>;
  errors: FieldErrors<QuizQuestionFormChoice>;
  control: Control<QuizQuestionFormChoice>;
  watch: UseFormWatch<QuizQuestionFormChoice>;
}

const MultipleChoiceAndSelectQuestion = ({
  select,
  register,
  errors,
  control,
  watch,
}: MultipleChoiceQuestionProps) => {
  const { t } = useTranslation('global');

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'questionValue.choices',
    rules: {
      required: true,
      minLength: 2,
    },
  } as never);

  const choices = watch('questionValue.choices');

  return (
    <div>
      <form>
        <div>
          {fields.map((field, index) => {
            return (
              <div key={field.id} className='d-flex align-items-center gap-2 my-2'>
                <div className='form-check'>
                  <input
                    id={`correctAnswer${index}`}
                    className='form-check-input'
                    disabled={!choices[index]}
                    {...register(
                      select ? 'questionValue.correctAnswers' : 'questionValue.correctAnswer',
                      { required: true, validate: (value) => !!value },
                    )}
                    value={choices[index]}
                    defaultChecked={false}
                    type={select ? 'checkbox' : 'radio'}
                  />
                  <label
                    className='form-check-label'
                    style={{ whiteSpace: 'nowrap' }}
                    htmlFor={`correctAnswer${index}`}
                  >
                    {t('quiz.correctAnswer')}
                  </label>
                </div>
                <input
                  className='form-control'
                  {...register(`questionValue.choices.${index}` as const, {
                    required: t('quiz.thisFieldIsRequired'),
                  })}
                />
                <button className='btn btn-danger' type='button' onClick={() => remove(index)}>
                  {t('delete')}
                </button>
              </div>
            );
          })}
        </div>
        <p className='text-muted fw-bold'>*{t('quiz.textboxFilledForSelect')}</p>
        {errors.questionValue &&
          ('correctAnswers' in errors.questionValue || 'correctAnswer' in errors.questionValue) && (
            <div className='alert alert-danger my-2'>{t('quiz.correctAnswerRequired')}</div>
          )}
        {errors.questionValue?.choices && (
          <div className='alert alert-danger my-2'>
            {t('quiz.atLeastXOptionsRequired', { x: 2 })}
          </div>
        )}
        <div className='d-flex flex-column my-2 gap-2'>
          <button type='button' className='btn btn-secondary' onClick={() => append('')}>
            {t('quiz.addOption')}
          </button>
        </div>
      </form>
    </div>
  );
};
export default MultipleChoiceAndSelectQuestion;
